import {
    Dispatch,
    FC,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoLogoGoogle } from 'react-icons/io';
import { CgFacebook } from 'react-icons/cg';
import { TiSocialLinkedin } from 'react-icons/ti';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { useToast } from 'components/common/Toast/ToastProvider';
import { googleProvider, facebookProvider } from 'utils/config';
import { Modal, Button, Input, Select } from 'components/common';
import { Dots } from 'components/common/Loader';
import { ClearError, signupUser, socialMediaAuth, GoogleSignup, FacebookSignup } from 'redux/authSlice/actions';
import firebase from 'firebase/compat';
import dataService from 'services/appData';
import successIcon from 'assets/icon/reserve-modal.svg';
import { RootState } from 'redux/rootReducer';
import 'react-phone-input-2/lib/style.css';
import './signup.css';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { linkedinClientId } from 'services/firebase-config';


interface SignupProps {
    openModal?: boolean;
    setOpenModal?: Dispatch<SetStateAction<boolean>>;
}

const Signup: FC<SignupProps> = ({ openModal = true, setOpenModal }) => {
    const { isLoading, error, message } = useSelector((state: RootState) => state.user);
    const [showForm, setShowForm] = useState(true);
    const [isSubmitClicked, setisSubmitClicked] = useState(false);
    const [success, setSuccess] = useState(false);
    // const [openModal, setOpenModal] = useState(true);
    const toast = useToast();
    const navigate = useNavigate();
    const [phonevalue, setPhoneValue] = useState('');
    const [contry_code, setcontry_code] = useState('');

    const [genderArr, setGenderArr] = useState<
        {
            genderID: number;
            genderCode: string;
            genderType: string;
        }[]
    >([]);
    const [selectedGender, setSelectedGender] = useState<Record<string, any>>();
    const [countryArr, setCountryArr] = useState<
        {
            countryID: number;
            countryName: string;
        }[]
    >([]);
    const [selectedCountry, setSelectedCountry] = useState<Record<string, any>>();
    const [stateArr, setStateArr] = useState<{ stateID: number; stateName: string }[]>([]);
    const [selectedState, setSelectedState] = useState<Record<string, any>>();
    const [phoneErr, setPhoneErr] = useState("");

    const dispatch = useDispatch();

    const fetchCountries = async () => {
        const response = await dataService.getCountries({
            pageNumber: 1,
            pageSize: 10,
        });
        setCountryArr(response.data);
    };

    const fetchStates = async (countryId: number) => {
        const res = await dataService.getStates({
            pageNumber: 1,
            pageSize: 10,
            countryId: countryId,
        });
        setStateArr(res.data);
    };

    const fetchGender = async () => {
        const res = await dataService.getGender({
            pageNumber: 1,
            pageSize: 10,
        });
        setGenderArr(res.data);
    };

    useEffect(() => {
        fetchCountries();
        fetchGender();
    }, []);

    useEffect(() => {
        console.log("isLoading", isLoading)
    }, [isLoading]);

    function setCharAt(str: string, index: number, chr: string) {
        if (index > str.length - 1) return str;
        return str.substring(0, index) + chr + str.substring(index + 1);
    }

    const validatePhone = (value: any, country: any) => {
        const { dialCode } = country;
        // console.log("dialCode", dialCode, value)
        setcontry_code(dialCode)
        let checker = value[dialCode.length];
        if (checker === '0') {
            let str = setCharAt(value, dialCode.length, '');
            return str;
        }

        return value;
    };

    const SignupSchema = Yup.object().shape({
        firstName: Yup.string().required('Please enter your First name'),
        lastName: Yup.string().required('Please enter your Last name'),
        // middleName: Yup.string(),
        emailAddress: Yup.string().email('Please enter a valid email').trim().required('Email is required'),
        referralCode: Yup.string(),
        // phoneNumber: Yup.string()
        //     // .matches(/^(\+)?234[0-9]*?.*/gm, 'Phone number must match 234***********')
        //     // .min(10)
        //     .required('Please enter your phone number')
        //     .trim(),
        // genderCode: Yup.string().required('Please select your gender'),
        // countryId: Yup.string().required('Please select your country'),
        // stateId: Yup.string().required('Please select your state'),
        // age: Yup.string().required('Age is a required field'),
        password: Yup.string()
            .trim()
            .required('Password is required')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\.\*])(?=.{10,})/,
                'Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
            ),
        over18: Yup.boolean().oneOf([true], "Please accept that you're over 18"),
        tandc: Yup.boolean().oneOf([true], 'Please accept the privacy policy'),
        // roomNumber: Yup.number().required('Room Number is required'),
    });

    const handleSocialAuth = async (provider: firebase.auth.AuthProvider, socialMethod: string) => {
        
        try {
            const res = await socialMediaAuth(provider);
            const user = res.multiFactor.user;

            // console.log("socialmethod", user)
            if(socialMethod === 'google') {
                await dispatch(
                    GoogleSignup({
                        id: user?.providerData[0]?.uid,
                        firstName: user.displayName.split(" ")[1],
                        lastName: user.displayName.split(" ")[0],
                        emailAddress: user.email,
                        middleName: '',
                        phoneNumber: user.phoneNumber || "",
                        accessToken: user.accessToken,
                        setOpenModal,
                        navigate
                    }
                ));
                return;
            }

            if(socialMethod === 'facebook') {
                await dispatch(
                    FacebookSignup({
                        id: user.uid,
                        firstName: user.displayName.split(" ")[1],
                        lastName: user.displayName.split(" ")[0],
                        emailAddress: user.email,
                        middleName: '',
                        phoneNumber: user.phoneNumber || "",
                        accessToken: user.accessToken,
                        setOpenModal,
                        navigate
                    }
                ));
                return;
            }
 
            
        } catch (error:any) {
            console.log("social-error", error)
        }
        
    };

    // const handleFurtherValidation = () => {
    //   // if (privacyPolicy.checked) {
    //   //   setprivacyPolicy({ checked: true, error: false });
    //   // } else {
    //   //   setprivacyPolicy({ checked: false, error: true });
    //   // }
    //   // if (!ageError) {
    //   //   setageError(false);
    //   // } else {
    //   //   setageError(true);
    //   // }
    //   // if (privacyPolicy.checked && !ageError) {
    //   //   setisSubmitClicked(true);
    //   // }
    //   setisSubmitClicked(true);
    // };

    const handleModalClose = () => {
        if (setOpenModal) {
            setOpenModal(false);
        } else {
            navigate('/');
        }
        dispatch(ClearError());
    };

    const { linkedInLogin } = useLinkedIn({
        clientId: linkedinClientId,
        redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
        onSuccess: (code) => {
          console.log(code);
        },
        onError: (error) => {
          console.log(error);
        },
      });

    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled && message?.toLowerCase().includes('success') && isSubmitClicked) {
            setSuccess(true);
            setShowForm(false);
        }
        console.log({ isCancelled, message, isSubmitClicked });
        return () => {
            isCancelled = true;
        };
    }, [message, isSubmitClicked]);

    return (
        <>
            {showForm ? (
                <Modal
                    openModal={openModal as boolean}
                    setOpenModal={setOpenModal as Dispatch<SetStateAction<boolean>>}
                    className="py-12 bg-white px-4 sm:px-10 w-full max-w-2xl Home-sign-up"
                    onOutsideClick={() => {}}
                    onModalClose={handleModalClose}
                >
                    <h3 className="text-2xl font-semibold leading-10 text-center">
                        Begin the <span className="text-auraPrimary">aura</span> experience
                    </h3>
                    <p className="text-center font-normal text-sm text-gray-4 mt-4">Use Your OpenID to Sign up</p>
                    <div className="flex w-full justify-center gap-2 mt-6">
                        <button
                            type="button"
                            onClick={() => {
                                handleSocialAuth(googleProvider, "google")
                                // setSocialMethod("google");
                            }}
                            className="flex items-center outline-none justify-center gap-1 sm:gap-4 text-[10px] sm:text-base rounded-[90px] sm:h-12 bg-[#DE5246] text-white w-full px-3 py-2 sm:py-4 sm:px-6"
                        >
                            <IoLogoGoogle /> Google
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                handleSocialAuth(facebookProvider, "facebook")
                                // setSocialMethod("facebook");
                            }}
                            className="flex items-center outline-none justify-center gap-1 sm:gap-4 text-[10px] sm:text-base rounded-[90px] sm:h-12 bg-black text-white w-full px-3 py-2 sm:py-4 sm:px-6"
                        >
                            <CgFacebook className="text-lg" /> Facebook
                        </button>
                        <button
                            type="button"
                            onClick={linkedInLogin}
                            className="flex items-center outline-none justify-center gap-1 sm:gap-4 text-[10px] sm:text-base rounded-[90px] sm:h-12 bg-[#3D6BE1] text-white w-full px-3 py-2 sm:py-4 sm:px-6"
                        >
                            <TiSocialLinkedin className="text-xl" /> Linkedin
                        </button>
                    </div>
                    <p className="text-center font-normal text-sm text-gray-4 mt-4">Or continue with email</p>
                    <Formik
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            emailAddress: '',
                            phoneNumber: '',
                            genderCode: '',
                            countryId: '',
                            stateId: '',
                            referralCode: '',
                            age: 21,
                            password: '',
                            roomNumber: 0,
                            over18: false,
                            tandc: false,
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={async (values, actions) => {
                            // console.log('submitting...', contry_code );
                            setisSubmitClicked(true);
                            const value = phonevalue.slice(contry_code.length, phonevalue.length)
                            if(value.toString().length < 10 ) {
                              setPhoneErr("Please provide a valid phone number")
                              setTimeout(()=>{
                                setPhoneErr("")
                              }, 4000)
                              return;
                            }
                            await dispatch(
                                signupUser({
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    emailAddress: values.emailAddress,
                                    referralCode: values.referralCode,
                                    middleName: ' ',
                                    phoneNumber: `+${phonevalue}`,
                                    genderId: '1',
                                    countryId: '1',
                                    stateId: '2',
                                    age: '21',
                                    password: values.password,
                                })
                            );
                            // setShowForm(false);
                            // setSuccess(true);
                        }}
                    >
                        {({ values, errors, touched, setFieldValue }) => (
                            <Form className="w-full grid grid-cols-2 gap-x-4 gap-y-2 mt-5" autoComplete='off' >
                                <div className="col-span-2">
                                    {error && (
                                        <div
                                            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full"
                                            role="alert"
                                        >
                                            {error && (error as unknown as string)}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <Input
                                        className="w-full mt-4"
                                        name="firstName"
                                        label="First Name"
                                        error={errors.firstName}
                                        touched={touched.firstName}
                                        autoComplete='off'
                                    />
                                </div>
                                <div>
                                    <Input
                                        className="w-full mt-4"
                                        name="lastName"
                                        label="Last Name"
                                        error={errors.lastName}
                                        touched={touched.lastName}
                                        autoComplete='off'
                                    />
                                </div>
                                {/* <div className="col-span-2">
                                    <Input
                                        className="w-full mt-4"
                                        name="emailAddress"
                                        label="Email Address"
                                        error={errors.emailAddress}
                                        touched={touched.emailAddress}
                                        autoComplete='off'
                                    />
                                </div> */}

                                <div>
                                    <Input
                                        className="w-full mt-4"
                                        name="emailAddress"
                                        label="Email Address"
                                        error={errors.emailAddress}
                                        touched={touched.emailAddress}
                                        autoComplete='off'
                                    />
                                </div>

                                <div>
                                    <Input
                                        className="w-full mt-4"
                                        name="referralCode"
                                        label="Referral Code"
                                        error={errors.referralCode}
                                        touched={touched.referralCode}
                                        autoComplete='off'
                                    />
                                </div>

                                <div>
                                    <Input
                                        className="w-full mt-6"
                                        name="password"
                                        type="password"
                                        label="Password"
                                        error={errors.password}
                                        touched={touched.password}
                                        autoComplete="new-password"
                                    />
                             
                                </div>
                                <div>
                                    <PhoneInput
                                        country={'ng'}
                                        value={phonevalue}
                                        autoFormat={false}
                                        placeholder="+234"
                                        inputProps={{
                                            name: 'phoneNumber',
                                            required: true,
                                            autoFocus: true,
                                        }}
                                        onChange={(value: any, country: any) => {
                                            // setPhoneValue(
                                            //   phonevalue.toString().startsWith('234')
                                            //     ? phonevalue.slice(0, 10)
                                            //     : phonevalue.slice(0, 10)
                                            // );
                                            setPhoneValue(validatePhone(value, country));
                                        }}
                                        inputStyle={{ height: '54px' }}
                                        containerStyle={{ height: '54px' }}
                                        containerClass="w-full mt-6 w-full border-0 rounded-[10px] h-13"
                                        inputClass="outline-0 h-full w-full p-0 border-0 focus:outline-none h-14 focus:border-none focus:shadow-none focus:ring-0 "
                                    />
                                    {phoneErr && <div className="text-small text-red-500">
                                      {phoneErr}
                                    </div>}
                                    {/* 
                                        CHOICES: Stick with the library & Fix this one last or have just a plane input with a border that has the validation
                                        <input
                                        type="text"
                                        className="border border-1 border-gray-300 rounded outline-0 w-full p-0 focus:outline-none h-14 focus:border-none focus:shadow-none focus:ring-0 "
                                        /> */}
                                </div>

                                {/* <Input
                                    className="w-full mt-6"
                                    name="phoneNumber"
                                    label="Phone"
                                    placeholder="23480123456789"
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/\D/g, '');
                                        setFieldValue('phoneNumber', value);
                                    }}
                                    value={values.phoneNumber as unknown as string}
                                    error={errors.phoneNumber}
                                    touched={touched.phoneNumber}
                                    /> */}

                                {/* <div>
                                    <Input
                                        className="w-full mt-6"
                                        name="age"
                                        label="Age"
                                        onChange={(e) => {
                                        const value = e.target.value.replace(/\D/g, '');
                                        setFieldValue('age', value);
                                        setageError(parseInt(e.target.value) > 18 ? false : true);
                                        }}
                                        value={values.age as unknown as string}
                                        error={errors.age}
                                        touched={touched.age}
                                    />
                                    {ageError && (
                                        <p className="text-red-500 text-left font-light">
                                        Age must be greater than 18
                                        </p>
                                    )}
                                    </div> */}

                                                    {/* <Select
                                    value={selectedCountry}
                                    onChange={setSelectedCountry}
                                    label={selectedCountry?.countryName}
                                    name="countryId"
                                    placeholder="Country"
                                    >
                                    {countryArr.map((country) => (
                                        <Option
                                        key={country.countryID}
                                        value={country}
                                        label={country.countryName}
                                        onSelect={() => fetchStates(country.countryID)}
                                        >
                                        {country.countryName}
                                        </Option>
                                    ))}
                                    </Select> */}
                                                    {/* <Select
                                    value={selectedState}
                                    onChange={setSelectedState}
                                    label={selectedState?.stateName}
                                    name="stateId"
                                    placeholder="State"
                                    >
                                    {stateArr.length > 0 ? (
                                        stateArr.map(
                                        (state: {
                                            stateID: Key | null | undefined;
                                            stateName:
                                            | string
                                            | number
                                            | boolean
                                            | JSX.Element
                                            | ReactElement<
                                                any,
                                                string | JSXElementConstructor<any>
                                                >
                                            | ReactFragment
                                            | ReactPortal
                                            | null
                                            | undefined;
                                        }) => (
                                            <Option
                                            key={state.stateID}
                                            value={state}
                                            label={state.stateName}
                                            >
                                            {state.stateName}
                                            </Option>
                                        )
                                        )
                                    ) : (
                                        <Option value="" label="">
                                        Select Country First
                                        </Option>
                                    )}
                                    </Select> */}

                                {/* <Select
                                    value={selectedGender}
                                    onChange={setSelectedGender}
                                    label={selectedGender?.genderType}
                                    name="genderCode"
                                    placeholder="Gender"
                                    buttonClass="mt-3"
                                    height="55px"
                                    >
                                    {genderArr.map((gender) => (
                                        <Option
                                        key={gender.genderID}
                                        value={gender}
                                        label={gender.genderType}
                                        >
                                        {gender.genderType}
                                        </Option>
                                    ))}
                                    </Select> */}
                                <div className="col-span-2">
                                    <div className=" flex items-center mt-2">
                                        <label>
                                            <Field type="checkbox" name="over18" />I am 18+
                                        </label>
                                    </div>
                                    <ErrorMessage
                                        render={(msg) => (
                                            <div className="text-start text-sm mt-3 font-light text-red-500">{msg}</div>
                                        )}
                                        name={'over18'}
                                    />
                                </div>

                                <div className="col-span-2">
                                    <div className=" flex items-center mt-2">
                                        <label>
                                            <Field type="checkbox" name="tandc" />
                                            You agree to our friendly
                                            <Link
                                                to="/privacy-policy"
                                                className="text-end text-auraPrimary text-sm font-normal underline"
                                            >
                                                privacy policy
                                            </Link>
                                        </label>
                                    </div>
                                    <ErrorMessage
                                        render={(msg) => (
                                            <div className="text-start text-sm mt-3 font-light text-red-500">{msg}</div>
                                        )}
                                        name={'tandc'}
                                    />
                                </div>

                                <div className="w-full mt-6 col-span-2 px-8">
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="w-full h-14 text-base"
                                        // onClick={handleFurtherValidation}
                                    >
                                        {isLoading ? <Dots /> : <span>Sign Up</span>}
                                    </Button>
                                </div>
                                <div className="text-xs space-x-1 text-center col-span-2 mt-5">
                                    <span> Already have an account?</span>

                                    <Link to="/login" className="text-auraPrimary hover:underline">
                                        Login
                                    </Link>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            ) : (
                <div>
                    {
                        success && (
                            <Modal
                                openModal={openModal as boolean}
                                setOpenModal={setOpenModal as Dispatch<SetStateAction<boolean>>}
                                className="pt-16 pb-12 bg-white px-4 sm:px-10 w-full max-w-[542px]"
                                onOutsideClick={() => navigate('/login')}
                                onModalClose={() => navigate('/login')}
                            >
                                <div className="p-6 md:py-12 flex flex-col items-center justify-center space-y-6">
                                    <img src={successIcon} className="w-40 " alt="" />
                                    <h3 className="mb-5 text-3xl font-semibold">Successful!</h3>
                                    <p className="text-base text-neutral font-normal text-center">
                                        Sign Up successful, kindly check your email and phone for a code to confirm your account
                                    </p>
                                    <div className="pb-8 w-full flex">
                                        <Link
                                            to="/login"
                                            className="py-3 rounded-xl text-base text-center font-normal mt-8 mx-auto px-6 sm:w-full bg-auraPrimary text-white capitalize hover:text-white"
                                        >
                                            Log In
                                        </Link>
                                    </div>
                                </div>
                            </Modal>
                        )
                        // : (
                        //   <Modal
                        //     openModal={openModal as boolean}
                        //     setOpenModal={setOpenModal as Dispatch<SetStateAction<boolean>>}
                        //     className="pt-16 pb-12 bg-white px-4 sm:px-10 w-full max-w-[542px]"
                        //     onOutsideClick={() => navigate('/login')}
                        //     onModalClose={() => navigate('/login')}
                        //   >
                        //     <div className="p-6 md:py-12 flex flex-col items-center justify-center space-y-6">
                        //       <img src={failureIcon} className="w-40 " alt="" />
                        //       <h3 className="mb-5 text-3xl font-semibold">Error!</h3>
                        //       <p className="text-base text-neutral font-normal text-center">
                        //         {error && (error as unknown as string)}
                        //       </p>
                        //       <div className="pb-8 w-full flex">
                        //         <button
                        //           type="button"
                        //           onClick={() => setShowForm(true)}
                        //           className=" py-3 rounded-xl text-base text-center font-normal mt-8 mx-auto px-6 bg-auraPrimary text-white capitalize"
                        //         >
                        //           Try again
                        //         </button>
                        //       </div>
                        //     </div>
                        //   </Modal>
                        // )
                    }
                </div>
            )}
        </>
    );
};

export default Signup;
