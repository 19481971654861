import { FC, Fragment, useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createDeviceToken, logout, setreloadCount, socialAuthentication, socialMediaAuth } from 'redux/authSlice/actions';
import { Button } from 'components/common';
import { ReactComponent as Bell } from 'assets/icon/bell.svg';
import Newlogo from 'assets/img/newlogos/1.png';
import ProfilePhoto from 'assets/icon/user.svg';
import Login from 'pages/Authentication/Login';
import Signup from 'pages/Authentication/Signup';
import ForgotPassword from 'pages/Authentication/ForgotPassword';
import { RootState } from 'redux/rootReducer';
import { firebaseVapidKey, messaging, onMessageListener } from 'services/firebase-config';
import { getToken } from 'firebase/messaging';
import PopOver from './PopOver';
import MenuComponent from './MenuComponent';
import NotificationComponent from './NotificationComponent';
import ApprovedModal from '../Modal/ApprovedModal';
import { setIsApprovedBooking } from 'redux/booking/action';
import bookingService from 'services/bookingService';
import { SetCurrentProductType } from 'redux/productSlice/actions';
import { simpleProductTypes } from 'utils/products';
import ComplaintModal from 'pages/UserProfile/ComplaintModal';

import { getSession, storage } from 'utils';
import Chatbot from '../Chatbot';
import { NotificationServices } from 'services/notificationService';
import dataService from 'services/appData';
import axios from 'axios';
import { loadingStart, loadingStopped, loginSuccess } from 'redux/authSlice/reducers';
import authService from 'services/authService';
import {
    ToastContextType,
    useToast,
  } from 'components/common/Toast/ToastProvider';
import queryString from 'query-string';
import { IoLogoWhatsapp } from 'react-icons/io';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

interface HeaderProps {
    isAuthenticated?: boolean;
    setMyFilter?: (arg0: any) => void;
    myFilter?: any;
}

const Header: FC<HeaderProps> = ({ isAuthenticated, setMyFilter, myFilter }: HeaderProps) => {
    const { isApprovedBookingExist } = useSelector((state: RootState) => state.booking);
    const { reloadCount } = useSelector((state: RootState) => state.user);
    const [openLogin, setOpenLogin] = useState(false);
    const [openSignup, setOpenSignup] = useState(false);
    const [openForgotPassword, setOpenForgotPassword] = useState(false);
    const [open, setOpen] = useState(false);
    const { user } = useSelector((state: RootState) => state.user);
    const [isTokenFound, setTokenFound] = useState(false);
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({ title: '', body: '' });
    const [singleBookingData, setsingleBookingData] = useState({});
    const [notificationData, setNotificationData] = useState([]);
    const [partnerClient, setPartnerClient] = useState([]);
    const [notificationLength, setNotificationLength] = useState(0);
    const [localNotificationLength, setLocalNotificationLength] = useState(localStorage.getItem('not'));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [headerToken, setHeaderToken] = useState('');

    const toast= useToast();
    const { auto } = queryString.parse(window.location.search);

    const fetchUserNotification = async () => {
        try {
            const response = await NotificationServices.getAllNotificationByUserId();
            const res = response.data.sort((p1: any, p2: any) => {
                return new Date(p2.dateCreated).getTime() - new Date(p1.dateCreated).getTime();
            });
            let returnedData = res.filter((item) => item.status == 'unread');
            setNotificationData(returnedData);
            setNotificationLength(returnedData.length);
        } catch (err) {}
    };

    const fetchAllPartnerClient = async () => {
        try {
            const response = await dataService.getAllPartnerClient();
            // const res = response.data.sort((p1: any, p2: any)=>{
            //   return new Date (p2.createdDate).getTime()  - new Date (p1.createdDate).getTime()
            // })
            const res = response.data;
            setPartnerClient(res.filter((item) => item.status !== 'INACTIVE'));
        } catch (err) {}
    };

    const extractMtnToken = () => {
        axios
            .get('https://auraweb.azurewebsites.net/')
            // .get('http://localhost:3002/')
            .then((response) => {
                // Access the response headers
                const headers: any = response.headers;

                // Extract the JWT token from the Authorization header
                 const token: any = headers.authorization; // or headers['authorization']
                const defaultToken = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InJibkxaMy1PM1BPd09pQ3JTOXJBcSJ9.eyJodHRwczovL215bXRuLmNvbS9sb2dpbkNvdW50IjoxMzgzLCJodHRwczovL215bXRuLmNvbS91c2VyTWV0YURhdGEiOnsia3ljIjp7ImRhdGVPZkJpcnRoIjoiMTk3OTEyMDgiLCJnZW5kZXIiOiJNIiwiZmlyc3ROYW1lIjoiQmVubmV0aCIsIm1pZGRsZU5hbWUiOiJOZHVrYSIsImxhc3ROYW1lIjoiSWJldG8iLCJuYXRpb25hbGl0eSI6Ik5JR0VSSUFOIiwicGhvbmVOdW1iZXIiOiI4MDY3ODExMzI1In0sImVtYWlsIjoiYmVubWF4eTJAeWFob28uY29tIiwiZmlyc3RfbmFtZSI6IkJlbm5ldGgiLCJtc2lzZG4iOiIyMzQ4MDM5NTc0OTg2Iiwibmlja19uYW1lIjoiQmVubmV0aCIsInRlcm1zX2FjY2VwdGVkIjoidHJ1ZSIsImxpbmtlZE51bWJlciI6IisyMzQ4MDY3ODExMzI1IiwibGlua2VkTnVtYmVyU3RhdHVzIjpmYWxzZSwidGhlbWUiOiJkYXJrIiwiYmlvIjoiIiwicGljIjoiaHR0cHM6Ly9tYWdlbnRvZWNvbW1lcmNlcHJvZC5tdG5uaWdlcmlhLm5ldC9tZWRpYS9tdG4vcHJvZmlsZS9pbWdfMTY4NDE2MTY4MS5qcGciLCJpbnRlcnN0aXRpYWxfZGF0ZSI6IjIwMjMxMTMwIiwidG91ckFwcERpc3BsYXkiOmZhbHNlLCJwcmVzdGlnZWRpc3BsYXkiOnRydWUsInNjYWxpbmciOiJub3JtYWwiLCJ0aGVtZUNoZWNrIjp0cnVlfSwiaHR0cHM6Ly9teW10bi5jb20vc2Vjb25kYXJ5UHJvZmlsZSI6W3sidXNlcl9pZCI6IjYxOWY2YjZlMTYxZWFmM2Q0MjNhYWJkMiIsInByb3ZpZGVyIjoic21zIiwiY29ubmVjdGlvbiI6InNtcyIsImlzU29jaWFsIjpmYWxzZX1dLCJodHRwczovL215bXRuLmNvbS9waG9uZV9udW1iZXIiOiIrMjM0ODAzOTU3NDk4NiIsImlzcyI6Imh0dHBzOi8vYXV0aC5tdG5vbmxpbmUuY29tLyIsInN1YiI6InNtc3w2MTlmNmI2ZTE2MWVhZjNkNDIzYWFiZDIiLCJhdWQiOlsiTmV4dEdlbkFQSSIsImh0dHBzOi8vbXRubmctcHJvZC5tdG4uYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTcwMzA2OTMwNCwiZXhwIjoxNzAzMDcyOTA0LCJhenAiOiJXTzVCYlR5RVdMU0Z2RlB3NVRzWW9pb1RRcWNxOE1xMyIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwgb2ZmbGluZV9hY2Nlc3MiLCJndHkiOlsicmVmcmVzaF90b2tlbiIsInBhc3N3b3JkIl19.gHM8bMMkkWjUHHfGcfEmCO6L8t4cMaVR0qSNwtwXvy-WciNndFbqS51c0OK9JnWDGh7oFxcIqaOBstC01o3h6fz1fSlqfRK6WHTi9Zmggj5uQp8zUSR01P-fBQSaIMF_YR_TzHFtpQvQgU4pQvNX0Qxg4VvK67p1w9mpdM9z_6k63o-pZ-6FUDOZLWcM08NBErahd37ba5xwcy13jqqOI38IM4lQ1CvV5YUd2O0ywSyqU_Tb6TCXwbnQohIMAQuy58TJCReXTWXY475DdqwfQPLplSBvuv8TdPu-9g1_pswA_gCfQvrRzMuTu43MUXb2R8bQetx20wz64f8Wd0O9AA'
                setHeaderToken(token);
            })
            .catch((error) => {
            });
    };

    const sendMTNToken = async () => {
        const data = {
            emailAddress: auto,
        };
        try {
            const response = await dataService.postMTNToken(data);
            if(response){
            try {
                dispatch(loadingStart());
                storage.setToken(response.data.accessToken);
                const user = await authService.fetchUserDetails();
                storage.setUserId(user?.data.userID);
                dispatch(loginSuccess(user.data));
                const url = new URL(window.location.href);
                const nextRoute = url.searchParams.get('redirect') || '/';
                navigate(nextRoute, {
                  replace: true,
                });
              } catch (err: any) {
                // dispatch(ProductLoadingStop(err.response.data.responseMessage));
                toast.error(err.response?.data.responseMessage, 5000);
                dispatch(loadingStopped(err.response?.data.responseMessage));
          
                // if(err.response?.data.responseMessage == 'You are yet to confirm your email') return setResend(true)
              }
            }
        } catch (err) {}
    };

    useEffect(() => {
        //comment cuz of CORS issues
        // fetchUserNotification();
    }, [user?.userID]);

    useEffect(() => {
        fetchAllPartnerClient();
    }, []);

    useEffect(() => {
       !user.userID && auto && sendMTNToken();
    }, [auto]);

    useEffect(()=>{
        !getSession(storage.getToken()) && user.userID &&  dispatch(logout(navigate))
    },[])

    const getTokenFn: any = (setTokenFound: (arg0: boolean) => void) => {
        return getToken(messaging, { vapidKey: firebaseVapidKey })
            .then(async (currentToken: any) => {
                if (currentToken) {
                    // console.log('current token for client: ', currentToken);
                    await dispatch(
                        createDeviceToken({
                            email: user.emailAddress,
                            deviceToken: currentToken,
                        })
                    );
                    setTokenFound(true);

                    // Track the token -> client mapping, by sending to backend server
                    // show on the UI that permission is secured
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                    setTokenFound(false);
                    // shows on the UI that permission is required
                }
            })
            .catch((err: any) => {
                console.log('An error occurred while retrieving token. ', err);
                // catch error while creating client token
            });
    };

    const checkBookingApproved = async () => {
        try {
            const res = await bookingService.getBookingsByProductID(29, null, null, 'approved');
            let isApprovedBookingExist = (res.data || []).length > 0;

            if (isApprovedBookingExist) {
                setsingleBookingData(res?.data[0]);
                dispatch(setIsApprovedBooking(true));
            } else {
                dispatch(setIsApprovedBooking(false));
            }
            // console.log('isApprovedBookingExist', isApprovedBookingExist);
        } catch (err) {}
    };

    //FUNCTIONS THAT SHOULD ONLY BE RUN ONCE ON LOADING / RELOAD;
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            getTokenFn(setTokenFound);
            dispatch(setreloadCount(reloadCount + 1));
        }
        return () => {
            isCancelled = true;
        };
    }, []);

    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            //comment cuz of CORS issues
            // checkBookingApproved();
            if (isApprovedBookingExist) {
                dispatch(setIsApprovedBooking(true));
                if (reloadCount <= 1 && isAuthenticated) {
                    setShow(true);
                } else {
                    setShow(false);
                }
            }
        }
        return () => {
            isCancelled = true;
        };
    }, [isApprovedBookingExist, isAuthenticated]);

    useEffect(() => {
        let isCancelled = false;

        if (!isCancelled) {
            if (isTokenFound) {
                console.log(' Notification permission enabled 👍🏻');
            } else {
                console.log(' Need notification permission ❗️ ');
            }
        }
        return () => {
            isCancelled = true;
        };
    }, [isTokenFound]);

    onMessageListener()
        .then(async (payload: any) => {
            setShow(true);
            setNotification({
                title: payload.notification.title,
                body: payload.notification.body,
            });
            // await dispatch(getnotification(payload));
            // console.log('notif payload', payload);
        })
        .catch((err) => console.log('failed: ', err));

    const [openModal1, setOpenModal1] = useState(false);

    let selectedData = {
        firstName: user?.firstName,
        lastName: user?.lastName,
        emailAddress: user?.emailAddress,
        phoneNumber: user?.phoneNumber,
    };

    const [isLoading, setisLoading] = useState(false);

    return (
        <>
            <main className="bg-white shadow sticky fit top-0 z-[10]">
                <>
                    <div className='hidden'>
                        <GoogleOAuthProvider clientId="105191217709-qv171ti5rvthgctvqe6at89eh0h6haib.apps.googleusercontent.com">
                            <GoogleLogin
                                onSuccess={async (response) => {
                                    const decodeToken: any = jwtDecode(response.credential);
                                        try {
                                        dispatch(loadingStart());
                                        const res = await authService.AuthenticateUserWithSocialMedia(
                                            {
                                            id: decodeToken?.sub,
                                            email: decodeToken?.email,
                                            isMobile: true
                                            }
                                        );
                                        storage.setToken(res.data.accessToken);
                                        const user = await authService.fetchUserDetails();
                                        storage.setUserId(user?.data.userID);
                                        dispatch(
                                            loginSuccess(user.data)
                                        );
                                        const url = new URL(window.location.href);
                                        const nextRoute = url.searchParams.get('redirect');
                                        navigate(nextRoute, {
                                            replace: true,
                                        });
                                        } catch (err: any) {
                                        toast?.error(err.response.data.responseMessage, 5000);
                                        }
                                }}
                                onError={() => console.log('Login Failed')}
                                useOneTap={Object.keys(user).length === 0 ? true : false}
                                ux_mode="popup" // Forces popup mode
                            />
                        </GoogleOAuthProvider>
                    </div>
                    <Login openModal={openLogin} setOpenModal={setOpenLogin} />
                    <ForgotPassword openModal={openForgotPassword} setOpenModal={setOpenForgotPassword} />
                    <Signup openModal={openSignup} setOpenModal={setOpenSignup} />
                    <ApprovedModal openModal={show} setOpenModal={setShow} singleBookingData={singleBookingData} />
                    <ComplaintModal
                        openModal={openModal1}
                        setOpenModal={setOpenModal1}
                        isLoading={isLoading}
                        setisLoading={setisLoading}
                        selectedData={selectedData}
                    />
                    <div className="md:mx-[100px] mx-[20px] py-4">
                        <div className="flex justify-between items-center mt-2 w-full">
                            <div className="lg:w-[120px] w-[80px]">
                                <Link to="/" className="inline-flex">
                                    <img src={Newlogo} className="w-100" />
                                </Link>
                            </div>
                            <div className="flex w-full items-center justify-end gap-2 sm:gap-6">
                                {simpleProductTypes?.map((data, index) => (
                                    <span
                                        // to={`/${data.productName}`}
                                        key={index}
                                        className="text-sm capitalize cursor-pointer font-medium hover:text-auraPrimary text-gray-4 hidden xl:flex"
                                        onClick={() => {
                                            dispatch(
                                                SetCurrentProductType({
                                                    productID: data.productID,
                                                    productName: data.productName,
                                                })
                                            );

                                            setMyFilter &&
                                                setMyFilter({
                                                    ...myFilter,
                                                    productId: data?.productID,
                                                });

                                            navigate(`/${data?.productName}`, { state: { productID: data?.productID } });
                                        }}
                                    >
                                        {data.productName}
                                    </span>
                                ))}

                                {/* <Link to="/faqs" className="text-sm font-medium hover:text-auraPrimary text-gray-4 hidden sm:flex whitespace-nowrap">
                                Help Center
                            </Link> */}

                                <div className="dropdown">
                                    <button className="dropbtn text-sm font-medium hover:text-auraPrimary text-gray-4 items-center sm:flex whitespace-nowrap">
                                        Partners &nbsp;
                                        <i className="fa fa-caret-down"></i>
                                    </button>
                                    <div className="dropdown-content">
                                        {partnerClient?.map((item, i) => (
                                            <Link
                                                to={item.id == 9 ? `/partners/omniverse` : `/partners/${item?.alias.toLowerCase()}/${item?.id}`}
                                                state={{ partnerID: item?.id }}
                                                key={i}
                                            >
                                                {item?.alias}
                                            </Link>
                                        ))}
                                    </div>
                                </div>

                                {(!isAuthenticated || !getSession(storage.getToken())) && (
                                    <>
                                        <Link
                                            to="/onboarding"
                                            className="text-sm font-medium flex-none text-gray-4 hover:text-auraPrimary hidden sm:flex"
                                        >
                                            {/* Become a host */}
                                            List a Property
                                        </Link>
                                        <Button
                                            type="button"
                                            variant="neutral"
                                            className="flex-none py-2 md:px-6 px-4"
                                            onClick={() => setOpenLogin(true)}
                                        >
                                            Sign in
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="primary"
                                            className="flex-none py-2 md:px-6 px-4"
                                            onClick={() => setOpenSignup(true)}
                                        >
                                            Sign up
                                        </Button>
                                    </>
                                )}

                                {isAuthenticated && getSession(storage.getToken()) && (
                                    <>
                                        {user.ishost ? (
                                            <Link
                                                to="/dashboard"
                                                className="border-2 border-gray-2 py-3 px-[18px] rounded-lg text-gray-6 hidden sm:block whitespace-nowrap"
                                            >
                                                Host Dashboard
                                            </Link>
                                        ) : (
                                            <Link
                                                to="/onboarding"
                                                className="text-sm font-medium flex-none text-gray-4 hover:text-auraPrimary hidden sm:flex"
                                            >
                                                List a Property
                                            </Link>
                                        )}

                                        {/* {/admin/i.test(user.userStatus) && (
                                        <Link
                                        to="/admin"
                                        className="border-2 border-gray-2 py-3 px-[18px] rounded-lg text-gray-6 hidden sm:flex"
                                        >
                                        Admin Dashboard
                                        </Link>
                                    )} */}

                                        <PopOver
                                            type="notification"
                                            icon={
                                                <div className="relative mr-4 md:mr-0">
                                                    {notificationLength > +localNotificationLength && (
                                                        <div className="h-3 w-3 rounded-[50%] bg-auraPrimary absolute top-[-10px] right-[-8px]" />
                                                    )}
                                                    <Bell />
                                                </div>
                                            }
                                        >
                                            <NotificationComponent
                                                notification={notification}
                                                setLocalNotificationLength={setLocalNotificationLength}
                                            />
                                        </PopOver>

                                        <PopOver
                                            type="menu"
                                            icon={
                                                <div className="inline-flex h-7 w-7 rounded-[50%] flex-none">
                                                    <img
                                                        src={ProfilePhoto}
                                                        alt="profile"
                                                        className="h-7 w-7 rounded-[50%] flex-none"
                                                    />
                                                </div>
                                            }
                                        >
                                            <MenuComponent setOpenModal1={setOpenModal1} />
                                        </PopOver>

                                        {/* <Button type="button" className="border py-2 px-2 sm:hidden" onClick={() => setOpen(!open)}>
                                        <HiMenu className="text-xl text-gray-5" />
                                    </Button> */}
                                    </>
                                )}
                            </div>
                        </div>
                        <div
                            className={`${
                                open ? 'h-auto sm:hidden flex flex-col w-full py-6 gap-2' : 'h-0 overflow-hidden p-0'
                            } transition-all`}
                        >
                            <Link to="/" className="bg-auraPrimary text-white px-4 py-2 rounded">
                                Home
                            </Link>
                            <Link to="/" className=" px-4 py-2 text-gray-4">
                                Support
                            </Link>
                            <Link to="/" className="px-4 py-2 text-gray-4">
                                Become a host
                            </Link>
                        </div>
                    </div>
                </>
            </main>

            {/* Chatbot chat button */}
            {/* <Chatbot /> */}

            {/* whatapp chat button */}
            <button
                className="bg-auraPrimary flex items-center justify-center rounded-full fixed h-[49px] w-[49px] bottom-[20px] right-[20px] z-40"
            >
               <IoLogoWhatsapp className='text-white text-[30px]' onClick={()=> window.open('https://wa.link/mulc9s')}/>
            </button>
        </>
    );
};

export default Header;
