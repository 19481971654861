import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IoLogoGoogle } from 'react-icons/io';
import { CgFacebook } from 'react-icons/cg';
import { TiSocialLinkedin } from 'react-icons/ti';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import {
  ToastContextType,
  useToast,
} from 'components/common/Toast/ToastProvider';
import { Modal, Button, Input } from 'components/common';
import { loginUser,
 socialAuthentication, socialMediaAuth } from 'redux/authSlice/actions';
import { facebookProvider, googleProvider } from 'utils/config';
import firebase from 'firebase/compat';
import Dot from 'components/common/Loader/Dot';
import dataService from 'services/appData';
import { linkedinClientId } from 'services/firebase-config';
import { useLinkedIn } from 'react-linkedin-login-oauth2';

interface LoginProps {
  openModal?: boolean;
  setOpenModal?: Dispatch<SetStateAction<boolean>>;
}

const Login: FC<LoginProps> = ({ openModal = true, setOpenModal }) => {
  // const [openModal, setOpenModal] = useState(true);
  const [isLoading, setLoading ] = useState(false)
  const [showResend, setResend ] = useState(false);
  const [email, setEmail] = useState('')
  const toast= useToast();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email')
      .trim()
      .required('Email is required'),
    password: Yup.string().trim().required('Password is required'),
  });

  const handleSocialAuth = async (provider: firebase.auth.AuthProvider) => {
    const res = await socialMediaAuth(provider);
    if (res.multiFactor.user) {
      const { displayName, email,phoneNumber,photoURL,accessToken, stsTokenManager, uid, providerData } = res.multiFactor.user;
      dispatch(socialAuthentication(
        {
          displayName,
          email,
          passportPath:photoURL,
          accessToken,
          refreshToken:stsTokenManager?.refreshToken,
          phoneNumber,
          uid: providerData[0]?.uid
        },
        setOpenModal,
        navigate,
        toast
      ))
    }
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: linkedinClientId,
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      console.log(code);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleResendOtp = async (e: any) =>{
    e.preventDefault();
    try {
      const response = await await dataService.resendEmailValidation(email); 
      toast?.success('Email validation successfully sent.');
      // setisLoading(false);
    } catch (err: any) {
      toast?.error(err.response.data.responseMessage, 5000);
    }
  }

  return (
    <Modal
      openModal={openModal as boolean}
      setOpenModal={setOpenModal as Dispatch<SetStateAction<boolean>>}
      className="pt-16 pb-12 bg-white px-4 sm:px-10 w-full max-w-[542px] Home-sign-in fit"
      onOutsideClick={() => {}}
      onModalClose={
        setOpenModal ? () => setOpenModal(false) : () => navigate('/')
      }
    >
      <h3 className="text-2xl font-semibold leading-10 text-center">
        Continue the <span className="text-auraPrimary">aura</span> experience
      </h3>
      <p className="text-center font-normal text-sm text-gray-4 mt-4">
        Welcome back
      </p>
      <div className="flex w-full justify-center gap-2 mt-6">
        <button
          type="button"
          onClick={() => handleSocialAuth(googleProvider)}
          className="flex items-center outline-none justify-center gap-1 sm:gap-4 text-[10px] sm:text-base rounded-[90px] sm:h-12 bg-[#DE5246] text-white w-full px-3 py-2 sm:py-4 sm:px-6"
        >
          <IoLogoGoogle /> Google
        </button>
        <button
          type="button"
          onClick={() => handleSocialAuth(facebookProvider)}
          className="flex items-center outline-none justify-center gap-1 sm:gap-4 text-[10px] sm:text-base rounded-[90px] sm:h-12 bg-black text-white w-full px-3 py-2 sm:py-4 sm:px-6"
        >
          <CgFacebook className="text-lg" /> Facebook
        </button>
        <button
          type="button"
          onClick={linkedInLogin}
          className="flex items-center outline-none justify-center gap-1 sm:gap-4 text-[10px] sm:text-base rounded-[90px] sm:h-12 bg-[#3D6BE1] text-white w-full px-3 py-2 sm:py-4 sm:px-6"
        >
          <TiSocialLinkedin className="text-xl" /> Linkedin
        </button>
      </div>
      <p className="text-center font-normal text-sm text-gray-4 mt-4">
        Or continue with email
      </p>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={LoginSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const { email, password } = values;
          setEmail(email)

          setLoading(true)
          await dispatch(
            loginUser(
              { email, password },
              navigate,
              toast as ToastContextType,
              setOpenModal as Dispatch<SetStateAction<boolean>>,
              setResend
            )
          );
          setLoading(false)
        }}
      >
        {({ values, errors, touched }) => (
          <Form className="w-full" autoComplete='off' >
            <Input
              className="w-full mt-6"
              name="email"
              label="Email Address"
              error={errors.email}
              touched={touched.email}
              autoComplete='off'
            />
            <Input
              className="w-full mt-6"
              name="password"
              type="password"
              label="Password"
              error={errors.password}
              touched={touched.password}
              autoComplete="new-password"
            />
            <div className="w-full flex justify-end mt-2">
            <Link
              to="/forgot-password"
              className="w-max  text-end block text-auraPrimary text-sm font-normal"
            >
              Forgot password?
            </Link>
            </div>
         
            <div className="w-full mt-6">
              <Button
                type="submit"
                variant="primary"
                className="w-full h-14 text-base"
              >
                {isLoading ? <Dot /> : <span>Log in</span>}
              </Button>
            </div>
            <div className="text-xs mt-5 space-x-2">
              <span> Don't have an account?</span>
             
              <Link to="/signup" className="text-auraPrimary hover:underline">
                Create account
              </Link>
              {
                showResend && (
                  <>
                    <p>or</p>
                    <button onClick={handleResendOtp} className="text-auraPrimary text-sm hover:underline">
                      Resend Email Validation
                    </button>
                  </>
                )
              }
               
              </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default Login;
